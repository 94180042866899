import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveCalendar } from "@nivo/calendar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_ANALYTICS } from "redux/slices/analyticsSlice";
import { FETCH_URL, CHART_THEME } from "utils/globalVariables";

import Grid from "layout/grid/Grid";
import { Widget } from "components/widgets/Widget";
import { Row, Column } from "layout/flexbot";

export default function DashboardHome() {
  const dispatch = useDispatch();
  const daily_visits = useSelector((state) => state.analytics.daily_visits);
  const site_visits = useSelector((state) => state.analytics.site_visits);
  const blog_page_visits = useSelector(
    (state) => state.analytics.blog_page_visits
  );
  const episode_page_visits = useSelector(
    (state) => state.analytics.episode_page_visits
  );
  const [week, setWeek] = useState([]);

  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    //get week for display
    const curr = new Date(); // get current date
    const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    let temp = [];
    for (let i = 0; i <= 6; i++) {
      let day = first + i;
      temp.push(new Date(curr.setDate(day)).toISOString().slice(0, 10));
    }
    setWeek(temp);
  }, []);

  //fetch analytics data
  useEffect(() => {
    fetch(FETCH_URL + "analytics", {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        dispatch(UPDATE_ANALYTICS(data.analytics));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <section style={{ marginLeft: "100px", color: "#fefefe" }}>
      <Grid width="100%" height="100%" columnMin="400px" rowMin="300px">
        <Widget height="300px">
          <ResponsiveBar
            data={daily_visits}
            keys={["value"]}
            indexBy={"day"}
            theme={CHART_THEME}
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "day",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "visits",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            role="application"
            ariaLabel="website visits this week"
            barAriaLabel={function (e) {
              return (
                e.id + ": " + e.formattedValue + " in country: " + e.indexValue
              );
            }}
          />
        </Widget>
        <Widget>
          <Column
            width="100%"
            height="100%"
            align="center"
            justify="space-around"
          >
            <h3>TOTAL SITE VISITS</h3>
            <p style={{ fontSize: "3rem" }}>{site_visits}</p>
          </Column>
        </Widget>
        <Widget>
          <Column
            width="100%"
            height="100%"
            align="center"
            justify="space-around"
          >
            <h3>BLOG PAGE VISITS</h3>
            <p style={{ fontSize: "3rem" }}>{blog_page_visits}</p>
          </Column>
        </Widget>
        <Widget>
          <Column
            width="100%"
            height="100%"
            align="center"
            justify="space-around"
          >
            <h3>EPISODE PAGE VISITS</h3>
            <p style={{ fontSize: "3rem" }}>{episode_page_visits}</p>
          </Column>
        </Widget>
        <Widget height="300px" spanColumn="2">
          <Row align="center">
            <button
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "green",
                border: "none",
                borderRadius: "5px",
              }}
              onClick={() => setYear(year - 1)}
            >
              {"<"}
            </button>
            <p style={{ fontSize: "1.5rem" }}>{year}</p>
            <button
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "green",
                border: "none",
                borderRadius: "5px",
              }}
              onClick={() => setYear(year + 1)}
            >
              {">"}
            </button>
          </Row>
          <ResponsiveCalendar
            data={daily_visits}
            from={`${year}-01-02`}
            to={`${year}-12-31`}
            emptyColor="#eeeeee"
            theme={CHART_THEME}
            colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
            yearSpacing={40}
            monthBorderColor="#ffffff"
            dayBorderWidth={2}
            dayBorderColor="#ffffff"
            legends={[
              {
                anchor: "bottom-right",
                direction: "row",
                translateY: 36,
                itemCount: 4,
                itemWidth: 42,
                itemHeight: 36,
                itemsSpacing: 14,
                itemDirection: "right-to-left",
              },
            ]}
          />
        </Widget>
      </Grid>
    </section>
  );
}
