import { useState, useEffect } from "react";
import { FETCH_URL } from "utils/globalVariables";

//component imports
import { Row, Column } from "layout/flexbot";
import { PostCardLarge, PostCardVert } from "components/cards";

import bg_green_2 from "assets/images/backgrounds/bg-green-2.png";

export default function Jumbotron() {
  const [posts, setPosts] = useState([]);
  const [featuredPost, setFeaturedPost] = useState({});
  useEffect(() => {
    fetch(FETCH_URL + "recentposts", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setPosts(data.posts);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    fetch(FETCH_URL + "blog/1", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setFeaturedPost(data.blog);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  return (
    <section
      style={{
        background: `url(${bg_green_2})`,
        backgroundSize: "cover",
        height: "max(700px, auto)",
        width: "100%",
        color: "#fefefe",
        padding: "75px 0",
      }}
    >
      <Column width="100%" height="100%">
        <h2 style={{ margin: "0 auto", fontSize: "3rem", textAlign: 'center' }}>
          KEEPING UP WITH THE BUMS
        </h2>
        <Row
          width="min(1200px, 100%)"
          customStyles="margin: 0 auto; flex: 1"
          wrap="wrap"
        >
          <Column height="100%" customStyles="min-width: 50%; flex: 1">
            <p>Fetured</p>
            <PostCardLarge
              title={featuredPost.title}
              description={featuredPost.description}
              link={featuredPost.id}
            />
          </Column>
          <Column height="100%" customStyles='flex: 1; min-width: min(400px, 100%)'>
            <p>Latest</p>
            {posts.map((post, index) => {
              return (
                <PostCardVert
                  key={index}
                  title={post.title}
                  link={post.id}
                  description={post.short_description}
                  date={post.date_posted}
                />
              );
            })}
          </Column>
        </Row>
      </Column>
    </section>
  );
}
