import { useState } from "react";
import { useSelector } from "react-redux";

import { Row } from "layout/flexbot";

import { PostCard } from "components/cards";

export default function FilteredDisplay({ category }) {
  const blogs = useSelector((state) => state.blog.blogs);
  const [lazyload, setLazyLoad] = useState(6);
  let fadein = 0;

  return (
    <>
      <p id={category}>{category}</p>
      <Row width="100%" justify="space-around">
        {blogs[category].map((post, index) => {
          fadein++;
          if (fadein <= lazyload) {
            return (
              <PostCard
                key={index}
                title={post.title}
                description={post.description}
                imageURL={post.imageURL}
                link={post.id}
              />
            );
          }
        })}
      </Row>
      {lazyload > fadein ? null : (
        <button onClick={() => setLazyLoad(lazyload + 6)}>Load More</button>
      )}
    </>
  );
}
