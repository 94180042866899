import styled from "styled-components";

const FixedNav = styled.nav`
  position: fixed;
  top: 0;
  height: 65px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: ${(props) => props.wrap || "wrap"};
  justify-content: ${(props) => props.justify || "start"};
  align-items: ${(props) => props.align || "start"};
  z-index: 5;
  transition: all 0.5s ease;
  ${(props) => props.customStyles}
`;

export default FixedNav;
