import styled from "styled-components";

const PlayButton = styled.button.attrs((props) => ({
  id: `play-button-${props.audioID}`,
  ariaLabel: `play button for ${props.audioID}`,
}))`
    position: relative;
    width: ${(props) => props.width || "60px"}};
    height: ${(props) => props.height || "60px"}};
    border-radius: 50%;
    border: solid #fefefe 5px;
    background: #007030;
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
    ${(props) => props.play && "animation: pulse 2s infinite"};
    @keyframes pulse {
        0% {
            transform: scale( .95);
            box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
        }
        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
        }
        100% {
            transform: scale(.95);
            box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
        }
    }
    &:before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 50%;
        box-shadow: 0px 0px 10px 5px #fff;
    }

`;

export default PlayButton;
