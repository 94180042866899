import { useEffect, useState } from "react";
import { FETCH_URL } from "utils/globalVariables";

export default function Analytics() {
  const [analytics, setAnalytics] = useState({});

  useEffect(() => {
    fetch(FETCH_URL + "analytics", {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAnalytics(data.analytics);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <div>
      <h1>Analytics</h1>
    </div>
  );
}
