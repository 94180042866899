import { Row } from "layout/flexbot";
import { useSelector, useDispatch } from "react-redux";
import { CHANGE_DISPLAYED_EPISODES } from "redux/slices/episodeSlice";

export default function Filter({ setFilterDisplay }) {
  const dispatch = useDispatch();
  const episodes = useSelector((state) => state.episode.episodes);

  const handleClick = (act) => {
    dispatch(CHANGE_DISPLAYED_EPISODES(act));
    setFilterDisplay(false);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        background: "rgba(0,0,0, .8)",
      }}
    >
      <Row width="100%" height="100%" align="center" justify="center">
        <div style={{ width: "80%", height: "80%", background: "#373737" }}>
          <button onClick={() => handleClick("all")}>ALL</button>
          {Object.keys(episodes).map((act, index) => (
            <button key={index} onClick={() => handleClick(act)}>
              {act}
            </button>
          ))}
        </div>
      </Row>
    </div>
  );
}
