import styled from "styled-components";

export const ContentForm = styled.form`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => props.columnMin || "600px"}, 1fr)
  );
  flex: 1;
  padding: 0 1rem;
  padding: 20px;
  border-radius: 0.8rem;
  color: #fefefe;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5;
  & input[type="text"],
  input[type="number"],
  textarea {
    padding: 0.5rem 1rem;
    border: 1px solid #000000;
    border-radius: 0.8rem;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5;
    color: #000000;
    &:focus {
      outline: none;
      border: 1px solid #ff0000;
    }
  }
`;
