import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { FETCH_URL } from "utils/globalVariables";

//comnponent imports
import { Column, Row } from "layout/flexbot";
import AudioController from "components/audio/AudioController";

export default function FeaturedEpisode() {
  const [episode, setEpisode] = useState({});
  const device = useSelector((state) => state.window.device);

  useEffect(() => {
    fetch(FETCH_URL + "episode/7", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setEpisode(data.episode);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <section
      style={{
        width: "min(1300px, 100%)",
        margin: "0 auto",
        padding: "20px 0",
        backgroundColor: "#373737",
        borderRadius: device !== "mobile" && "0.8rem",
        transform: device !== "mobile" && "translateY(-50%)",
        color: "#fefefe",
        boxShadow: device !== "mobile" && "0 25px 50px #000",
      }}
    >
      <Row
        height="100%"
        width="90%"
        align="center"
        gap="40px"
        customStyles="padding-left: 5%"
      >
        <Column
          width={device !== "mobile" ? "40%" : "100%"}
          customStyles={
            device === "mobile" && "text-align: center; align-items: center"
          }
          height="70%"
          gap="10px"
          wrap="no-wrap"
        >
          <h2 style={{ color: "#007030", fontSize: ".8rem" }}>
            FEATURED EPISODE
          </h2>
          <h3 style={{ fontSize: "1.7rem" }}>{episode.title}</h3>
          <p style={{ fontSize: ".8rem" }}>{episode.description}</p>
        </Column>
        <AudioController audioURL={episode.episode_audio} audioID={episode.id} />
      </Row>
    </section>
  );
}
