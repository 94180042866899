import { useEffect } from "react";
import { FETCH_URL } from "utils/globalVariables";
import { useDispatch } from "react-redux";
import { LOGOUT } from "redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
//component imports
import { DashboardNav } from "layout/navigation";
import DashboardHeader from "./header/DashboardHeader";
import { Outlet } from "react-router-dom";
import { DashboardLink } from "components/links";

//image imports
import home_icon from "assets/images/navigation/home_icon.png";
import podcast_icon from "assets/images/navigation/podcast_icon.png";
import blog_icon from "assets/images/navigation/blog_icon.png";
import analytics_icon from "assets/images/navigation/analytics_icon.png";

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //user check to see if user is logged in and redirect to dashboard if they are
  // useEffect(() => {
  //   fetch(FETCH_URL + "login/refresh", {
  //     method: "GET",
  //     credentials: "include",
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         return res.json();
  //       } else {
  //         localStorage.removeItem("access_token_cookie");
  //         dispatch(LOGOUT());
  //         navigate("/login");
  //       }
  //     })
  //     .then((data) => {})
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // }, []);

  function Logout() {
    fetch(FETCH_URL + "logout", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch(LOGOUT());
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <main
      style={{ width: "100%", minHeight: "100vh", backgroundColor: "#222222" }}
    >
      <DashboardNav>
        <DashboardLink to="/dashboard" image={home_icon} />
        <DashboardLink to="/dashboard/podcast" image={podcast_icon} />
        <DashboardLink to="/dashboard/blog" image={blog_icon} />
        <DashboardLink to="/dashboard/analytics" image={analytics_icon} />
        <button onClick={() => Logout()}>Logout</button>
      </DashboardNav>
      <DashboardHeader />
      <Outlet />
    </main>
  );
}
