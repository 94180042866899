import ContactForm from "components/forms/ContactForm";
import { Row } from "layout/flexbot";

export default function Contact() {
  return (
    <div
      style={{
        paddingTop: "75px",
        textAlign: "center",
        backgroundColor: "#373737",
        color: "#fefefe",
      }}
    >
      <h1>We'd love to hear from you!</h1>
      <Row gap="40px" width="min(1300px, 100%)" customStyles="margin: 0 auto">
        <ContactForm />
      </Row>
    </div>
  );
}
