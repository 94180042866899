import { useEffect, useState } from "react";

import FixedNav from "./FixedNav";
import { Row } from "layout/flexbot";
import { NavLink } from "components/links";

export default function DesktopNav() {
  const [navStyles, setNavStyles] = useState("transparent");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setNavStyles("background: #373737; box-shadow: 5px 0 10px #000");
      } else {
        setNavStyles("background: transparent; box-shadow: none");
      }
    });
  }, []);

  return (
    <FixedNav align="center" justify="space-between" customStyles={navStyles}>
      <Row justify="space-around" customStyles="width: 38%">
        <NavLink to="podcast">Podcast</NavLink>
        <NavLink to="blog">Blog</NavLink>
      </Row>
      <NavLink
        to="/"
        style={{
          backgroundColor: "white",
          flex: 1,
          height: "100%",
          textAlign: "center",
          color: "#000",
        }}
      >
        <h1>The Bums</h1>
      </NavLink>
      <Row justify="space-around" customStyles="width: 38%">
        <NavLink to="about">About</NavLink>
        <NavLink to="contact">Contact</NavLink>
      </Row>
    </FixedNav>
  );
}
