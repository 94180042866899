import styled from "styled-components";
import { useSelector } from "react-redux";

const SidebarNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 60px;
  height: 100vh;
  background: #373737;
  box-shadow: 5px 0 10px #000;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const BottomNav = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background: #373737;
  box-shadow: 0 -5px 10px #000;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export default function DashboardNav({ children }) {
  const window = useSelector((state) => state.window.device);

  return (
    <>
      {window === "mobile" ? (
        <BottomNav>{children}</BottomNav>
      ) : (
        <SidebarNav>{children}</SidebarNav>
      )}
    </>
  );
}
