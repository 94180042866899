import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "components/links";

import { FETCH_URL } from "utils/globalVariables";
import { sortByParameter } from "utils/utils";
import Grid from "layout/grid/Grid";
import { Row } from "layout/flexbot";
import { ButtonMain } from "components/buttons";

export default function ContentManager({ type }) {
  const episodePageVisits = useSelector(
    (state) => state.analytics.episode_page_visits
  );
  const blogPageVisits = useSelector(
    (state) => state.analytics.blog_page_visits
  );
  const singleEpisodeListens = useSelector(
    (state) => state.analytics.single_episode_listens
  );
  const singleBlogVisits = useSelector(
    (state) => state.analytics.single_blog_visits
  );
  const [content, setContent] = useState({});

  useEffect(() => {
    fetch(FETCH_URL + type, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setContent(data[type]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [type]);

  return (
    <section style={{ zIndex: 1, marginLeft: "100px", color: "#fefefe" }}>
      <h1 style={{ textAlign: "center" }}>The Bums Website {type} Manager</h1>
      <Row justify="space-around">
        <div id="total-episodes">
          <p>Total number of {type}</p>
          <p>{Object.keys(content).length}</p>
        </div>
        <div>
          <p>{type} Page visits</p>
          <p>{type === "episodes" ? episodePageVisits : blogPageVisits}</p>
        </div>
        <div>
          <p>Most popular {type}</p>
          <p>TBD</p>
        </div>
        <div>
          <p>least popular {type}</p>
          <p>TBD</p>
        </div>
      </Row>
      <Row align="center" justify="space-between">
        <div>
          <p>Filter:</p>
          <ButtonMain
            onClick={() =>
              setContent(
                sortByParameter(
                  content,
                  type === "episodes" ? "episode_number" : "blog_number"
                )
              )
            }
          >
            {type} number
          </ButtonMain>
          <ButtonMain
            onClick={() => setContent(sortByParameter(content, "guests"))}
          >
            guests
          </ButtonMain>
          <ButtonMain
            onClick={() => setContent(sortByParameter(content, "act"))}
          >
            act
          </ButtonMain>
          <ButtonMain
            onClick={() => setContent(sortByParameter(content, "title"))}
          >
            title
          </ButtonMain>
        </div>
        {/* need updating below */}
        <NavLink
          to={
            type === "episodes"
              ? `/dashboard/podcast/new`
              : "/dashboard/blog/new"
          }
          style={{
            backgroundColor: "#fefefe",
            color: "#373737",
            padding: "5px",
            borderRadius: ".5rem",
          }}
        >
          Add {type}
        </NavLink>
      </Row>

      <Grid customStyles="grid-template-columns: repeat(6, 1fr); p, a {border: solid #fefefe 1px; padding: 5px;}">
        <p>Title</p>
        <p>{type === "episodes" ? "Listens" : "Reads"}</p>
        <p>{type} Number</p>
        <p>{type === "episodes" ? "Description" : "Date Posted"}</p>
        <p>{type === "episodes" ? "guests" : "Short Description"}</p>
        <p>{type === "episodes" ? "act" : "category"}</p>
        {Object.keys(content).map((key, index) => (
          <React.Fragment key={index}>
            <NavLink
              to={
                type === "episodes"
                  ? `/dashboard/podcast/${content[key].id}`
                  : `/dashboard/blog/${content[key].id}`
              }
            >
              {content[key].title}
            </NavLink>
            <p>
              {type === "episodes"
                ? singleEpisodeListens[content[key].id]
                : singleBlogVisits[content[key].id]}
            </p>
            <p>
              {type === "episodes"
                ? content[key].episode_number
                : content[key].user_id}
            </p>
            <p>
              {type === "episodes"
                ? content[key].description
                : content[key].date_posted}
            </p>
            <p>
              {type === "episodes"
                ? content[key].guests
                : content[key].short_description}
            </p>
            <p>
              {type === "episodes" ? content[key].act : content[key].category}
            </p>
          </React.Fragment>
        ))}
      </Grid>
    </section>
  );
}
