import styled from "styled-components";

const ButtonMain = styled.button`
  background-color: ${(props) => props.bgColor || "#fefefe"};
  color: ${(props) => props.color || "#4D5859"};
  font-size: ${(props) => props.fontSize || "1.2rem"};
  font-weight: ${(props) => props.fontWeight || "400"};
  padding: ${(props) => props.padding || ".5rem 1.5rem"};
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.borderRadius || "0.4rem"};
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: ${(props) => props.hoverBgColor || "#007030"};
    color: ${(props) => props.hoverColor || "#fefefe"};
  }
`;

export default ButtonMain;
