import { useEffect } from "react";
import { FETCH_URL } from "utils/globalVariables";

import Jumbotron from "./jumbotron/Jumbotron";
import BlogDisplay from "./blogDisplay/BlogDisplay";

export default function Blog() {
  useEffect(() => {
    fetch(FETCH_URL + "analyticRecord", {
      method: "POST",
      body: JSON.stringify({ type: "blogs" }),
    })
      .then((res) => {
        if (res.status === 200) {
        } else {
          throw new Error("Analytic not recorded");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <div>
      <Jumbotron />
      <BlogDisplay />
    </div>
  );
}
