import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_EPISODES, UPDATE_NUMERICAL_EPISODES } from "redux/slices/episodeSlice";
import { FETCH_URL } from "utils/globalVariables";

//component imports
import { Row, Column } from "layout/flexbot";
import AudioMain from "components/audio/AudioMain";
import Filter from "../filter/Filter";

//image imports
import landscape from "assets/images/backgrounds/landscape.webp";

export default function PodcastMenu() {
  const [currentEpisode, setCurrentEpisode] = useState(0);
  const [filterDisplay, setFilterDisplay] = useState(false);
  const [error, setError] = useState(false);
  const [displayedError, setDisplayedError] = useState("");
  const episodes = useSelector((state) => state.episode.episodes);
  const numerical_episodes = useSelector((state) => state.episode.numerical_episodes);
  const displayed_episodes = useSelector(
    (state) => state.episode.displayed_episodes
  );
  const dispatch = useDispatch();

  useEffect(() => {
    fetch(FETCH_URL + "episodes", {
      method: "GET",
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          setError(true);
          setDisplayedError("failed to fetch episodes");
        }
      })
      .then((data) => {
        dispatch(UPDATE_NUMERICAL_EPISODES(data.episodes));
        let tempSorted = {};
        data.episodes.map((episode) => {
          if (episode.act in tempSorted) {
            tempSorted[episode.act].push(episode);
          } else {
            tempSorted[episode.act] = [episode];
          }
        });
        dispatch(UPDATE_EPISODES(tempSorted));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  function toggleFilter() {
    const episodeList = document.getElementById("episode-list");
    episodeList.scrollTop = 0;
    setFilterDisplay(!filterDisplay);
  }

  return (
    <div
      style={{
        margin: "0 auto",
        borderRadius: "15px",
        color: "#fefefe",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `url(${landscape})`,
          backgroundSize: "cover",
          filter: "blur(5px)",
          zIndex: -1,
        }}
      ></div>
      <Row
        height="100px"
        align="center"
        customStyles="margin: 0 auto; padding: 10px; background-color: #373737;"
      >
        <img
          src={
            currentEpisode.episode_image
              ? FETCH_URL + "image/" + currentEpisode.episode_image
              : FETCH_URL + "image/episode_default.jpg"
          }
          alt="struggling with the bums podcast logo"
          height="100%"
          style={{ borderRadius: "15px" }}
        />
        <Column height="100%" justify="space-between" customStyles="flex: 1">
          <Row width="100%" justify="space-between">
            <p>Struggling With The Bums</p>
            <Row gap="40px">
              <button onClick={() => toggleFilter()}>Filter</button>
              <p>Share</p>
              <p>Follow</p>
            </Row>
          </Row>
          <p>{currentEpisode.title}</p>
          <AudioMain
            episode={currentEpisode}
            audioURL={currentEpisode.episode_audio}
            audioID={currentEpisode.id}
          />
        </Column>
      </Row>
      <Column
        id="episode-list"
        height="500px"
        customStyles={`overflow-Y: ${
          filterDisplay ? "hidden" : "auto"
        }; position: relative; background-color: rgba(55,55,55,.9)`}
      >
        <p>{error && displayedError}</p>
        <div
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
        >
          {displayed_episodes === "all"
            ? numerical_episodes.map((episode, index) => (
                  <button
                    key={index}
                    style={{
                      width: "100%",
                      background: "none",
                      border: "none",
                      color: "white",
                    }}
                    onClick={() => setCurrentEpisode(episode)}
                  >
                    <Row
                      height="40px"
                      align="center"
                      key={index}
                      customStyles="border-bottom: solid white 2px"
                    >
                      <p>{episode.episode_number}</p>
                      <img
                        src={
                          episode.episode_image
                            ? FETCH_URL + "image/" + episode.episode_image
                            : FETCH_URL + "image/episode_default.jpg"
                        }
                        alt=""
                        height="30px"
                      />
                      <p>{episode.title}</p>
                    </Row>
                  </button>
              ))
            : episodes[displayed_episodes].map((episode, index) => (
                <button
                  key={index}
                  style={{
                    width: "100%",
                    background: "none",
                    border: "none",
                    color: "white",
                  }}
                  onClick={() => setCurrentEpisode(episode)}
                >
                  <Row
                    height="40px"
                    align="center"
                    key={index}
                    customStyles="border-bottom: solid white 2px"
                  >
                    <p>{episode.episode_number}</p>
                    <img
                      src={
                        episode.episode_image
                          ? FETCH_URL + "image/" + episode.episode_image
                          : FETCH_URL + "image/episode_default.jpg"
                      }
                      alt=""
                      height="30px"
                    />
                    <p>{episode.title}</p>
                  </Row>
                </button>
              ))}
        </div>
        {filterDisplay && <Filter setFilterDisplay={setFilterDisplay} />}
      </Column>
    </div>
  );
}
