import styled from "styled-components";

export const DeleteButton = styled.button`
  background-color: #ff0000;
  color: #ffffff;
  border: none;
  border-radius: 0.8rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #ffffff;
    color: #ff0000;
    border: 1px solid #ff0000;
  }
`;
