import Card from "../Card";
import { FETCH_URL } from "utils/globalVariables";
import { Link } from "react-router-dom";

export default function PostCardLarge({
  title,
  description,
  date = "1/1/04",
  image = "blog_default.jpeg",
  link = "/default_post",
}) {
  return (
    <Card
      customStyles="flex: 1"
      backgroundColor="none"
      shadow="none"
      color="#fefefe"
    >
      <img
        src={FETCH_URL + "image/" + image}
        alt=""
        width="80%"
        style={{ border: "solid #fefefe 3px", borderRadius: "10px" }}
      />
      <p>{date}</p>
      <h3>{title}</h3>
      <p>{description}</p>
      <Link to={`${link}`} style={{ color: "#fefefe" }}>
        Read Now
      </Link>
    </Card>
  );
}
