import styled from "styled-components";

export const Widget = styled.div`
  grid-column: span ${(props) => props.spanColumn || 1};
  grid-row: span ${(props) => props.spanRow || 1};
  grid-area: ${(props) => props.area};
  margin: 10px;
  padding: 10px;
  height: ${(props) => props.height};
  background-color: ${(props) =>
    props.backgroundColor || "rgba(55, 55, 55, 0.8)"};
  border-radius: 10px;
  z-index: 1;
`;
