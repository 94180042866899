import { useSelector } from "react-redux";

import MobileNav from "./MobileNav";
import DesktopNav from "./DesktopNav";

export default function MainNav() {
  const device = useSelector((state) => state.window.device);

  if (device === "mobile") {
    return <MobileNav />;
  } else {
    return <DesktopNav />;
  }
}
