import { useState, useEffect } from "react";

import Card from "../Card";
import { Row, Column } from "layout/flexbot";
import { FETCH_URL } from "utils/globalVariables";
import { Link } from "react-router-dom";

export default function PostCardVert({
  title,
  date,
  description,
  image = "blog_default.jpeg",
  link = "",
}) {
  const [formattedDate, setFormattedDate] = useState(date);

  useEffect(() => {
    const tempDate = new Date(date);
    setFormattedDate(tempDate.toISOString().slice(0, 10));
  }, [date]);

  return (
    <Card
      customStyles="flex: 1"
      backgroundColor="none"
      shadow="none"
      color="#fefefe"
    >
      <Row wrap='wrap'>
        <img
          src={FETCH_URL + "image/" + image}
          alt=""
          width="30%"
          style={{ border: "solid #fefefe 3px", borderRadius: "10px" }}
        />
        <Column customStyles='flex: 1; min-width: 250px'>
          <p>{formattedDate}</p>
          <h3>{title}</h3>
          <p>{description}</p>
          <Link to={`${link}`} style={{ color: "#fefefe" }}>
            Read Now
          </Link>
        </Column>
      </Row>
    </Card>
  );
}
