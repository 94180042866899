import { Card } from "..";
import { Column } from "layout/flexbot";
import AudioController from "components/audio/AudioController";
import { FETCH_URL } from "utils/globalVariables";

export default function EpisodeCard({
  title,
  description,
  audioURL,
  audioID,
  image = "episode_default.jpg",
}) {
  return (
    <Card width="min(400px, 100%)" customStyles="color: #fefefe;">
      <Column
        width="100%"
        align="center"
        gap="20px"
        customStyles="text-align: center"
      >
        <img
          src={FETCH_URL + "image/" + image}
          alt="episode default"
          width="80%"
          style={{ border: "solid #fefefe 4px", borderRadius: "15px" }}
        />
        <h3>{title}</h3>
        <p style={{ fontSize: "1rem" }}>{description}</p>
        <AudioController audioURL={audioURL} audioID={audioID} />
      </Column>
    </Card>
  );
}
