const FETCH_URL = "https://www.strugglingbums.com/api/";
const ANCHOR_LINK = "https://anchor.fm/bryce095";
const ITUNES_LINK =
  "https://podcasts.apple.com/us/podcast/struggling-with-the-bums/id1562250212?itscg=30200&itsct=podcast_box_link&ls=1";
const SPOTIFY_LINK =
  "https://open.spotify.com/show/7mINXL8B0gIOJpqkUhW1UV?si=3e70421eed504d8f&nd=1";
const INSTAGRAM_LINK = "https://www.instagram.com/thebu_ms/";
const EDDIE_SOCIALS = {
  instagram: "https://www.instagram.com/ecano13/",
  twitter: "https://twitter.com/ecano131",
};
const BRYCE_SOCIALS = {
  instagram: "https://www.instagram.com/bwinn12/",
  twitter: "https://twitter.com/ALLiDoisWINN12",
};
const DANIEL_SOCIALS = {
  instagram: "https://twitter.com/ALLiDoisWINN12/",
  twitter: "https://twitter.com/IAM_DLDXII",
};

const CHART_THEME = {
  background: "none",
  textColor: "#fefefe",
  fontSize: 11,
  axis: {
    domain: {
      line: {
        stroke: "#fefefe",
        strokeWidth: 1,
      },
    },
    legend: {
      text: {
        fontSize: 12,
        fill: "#fefefe",
      },
    },
    ticks: {
      line: {
        stroke: "#fefefe",
        strokeWidth: 1,
      },
      text: {
        fontSize: 11,
        fill: "#fefefe",
      },
    },
  },
  grid: {
    line: {
      stroke: "#fefefe",
      strokeWidth: 1,
    },
  },
  legends: {
    title: {
      text: {
        fontSize: 11,
        fill: "#fefefe",
      },
    },
    text: {
      fontSize: 11,
      fill: "#fefefe",
    },
    ticks: {
      line: {},
      text: {
        fontSize: 10,
        fill: "#fefefe",
      },
    },
  },
  annotations: {
    text: {
      fontSize: 13,
      fill: "#fefefe",
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    link: {
      stroke: "#000000",
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    outline: {
      stroke: "#000000",
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    symbol: {
      fill: "#000000",
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
  },
  tooltip: {
    container: {
      background: "#ffffff",
      color: "#373737",
      fontSize: 12,
    },
    basic: {},
    chip: {},
    table: {},
    tableCell: {},
    tableCellValue: {},
  },
};

export {
  FETCH_URL,
  ANCHOR_LINK,
  ITUNES_LINK,
  SPOTIFY_LINK,
  CHART_THEME,
  INSTAGRAM_LINK,
  EDDIE_SOCIALS,
  BRYCE_SOCIALS,
  DANIEL_SOCIALS,
};
