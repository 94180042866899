import { Column } from "layout/flexbot";

import bg_green_2 from "assets/images/backgrounds/bg-green-2.png";

export default function AboutHeader() {
  return (
    <div
      style={{
        height: "min(400px, 100vh)",
        background: `url(${bg_green_2})`,
        backgroundSize: "cover",
        color: "#fefefe",
      }}
    >
      <Column
        width="min(1000px, 100%)"
        height="100%"
        align="center"
        justify="center"
        customStyles="font-size: 1.2rem; margin: 0 auto; text-align: center"
        wrap='no-wrap'
      >
        <h1>Digging Deeper With The Bums</h1>
        <p>
          Hello fellow bums, we are a trio of good friends who are hoping to
          spark earnest conversations about topics people are usually unwilling
          to discuss or bring up with others.
        </p>
      </Column>
    </div>
  );
}
