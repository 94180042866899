//components imports
import { Column } from "layout/flexbot";
import { ButtonMain } from "components/buttons";
import { FadeText } from "components/text";

//image imports
import forest_soundwave from "assets/images/backgrounds/forest-soundwave.jpeg";

export default function Jumbotron() {

  function scrollButton() {
    let newsletter = document.getElementById("newsletter");
    newsletter.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <section
      style={{
        background: `url(${forest_soundwave})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "min(800px, 100vh)",
        width: "100%",
      }}
    >
      <Column
        justify="center"
        align="center"
        gap="40px"
        customStyles="height: 100%"
      >
        <h1
          style={{
            color: "#fefefe",
            fontSize: "3rem",
            margin: "0 10%",
            textAlign: "center",
          }}
        >
          <FadeText fade=".5" text="Struggling" /> &nbsp;
          <FadeText text="With" /> &nbsp;
          <FadeText fade="1.5" text="The" /> &nbsp;
          <FadeText fade="2" text="Bums" />
        </h1>
        <ButtonMain onClick={() => scrollButton()}>Join The Club</ButtonMain>
      </Column>
    </section>
  );
}
