import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function DashboardLink({
  to,
  width = "40px",
  height,
  image,
  alt,
}) {
  const location = useLocation();

  return (
    <Link
      to={to}
      style={{
        backgroundColor: location.pathname === to && "rgba(44, 220, 217, .3)",
        borderRadius: location.pathname === to && ".7rem",
        boxShadow: location.pathname === to && "-3px -3px 5px black inset",
      }}
    >
      <img width={width} height={height} src={image} alt={alt} />
    </Link>
  );
}
