import { createSlice } from "@reduxjs/toolkit";

export const blogSlice = createSlice({
  name: "blog",
  initialState: {
    current_post: 1,
    blogs: {},
  },
  reducers: {
    //updates user values from a dictionary action
    CHANGE_BLOG: (state, action) => {
      state.current_post = action.payload;
    },
    UPDATE_BLOGS: (state, action) => {
      state.blogs = action.payload;
    },
  },
});

export const { CHANGE_BLOG, UPDATE_BLOGS } = blogSlice.actions;
export default blogSlice.reducer;
