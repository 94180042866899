import styled from "styled-components";

const LinkMain = styled.a.attrs((props) => ({
  href: props.href || "#",
  target: props.target || "_blank",
  rel: props.rel || "noopener noreferrer",
}))`
  background-color: ${(props) => props.bgColor || "#fefefe"};
  color: ${(props) => props.color || "#4D5859"};
  text-decoration: none;
  font-size: ${(props) => props.fontSize || "1rem"};
  font-weight: ${(props) => props.fontWeight || "400"};
  padding: ${(props) => props.padding || ".2rem 1rem"};
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.borderRadius || "0.4rem"};
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: ${(props) => props.hoverBgColor || "#007030"};
    color: ${(props) => props.hoverColor || "#fefefe"};
  }
`;

export default LinkMain;
