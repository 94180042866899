import { useState, useEffect } from "react";
import { FETCH_URL } from "utils/globalVariables";

//component imports
import { Column, Row } from "layout/flexbot";
import { EpisodeCard } from "components/cards";

import bg_green_2 from "assets/images/backgrounds/bg-green-2.png";

export default function LatestEpisodes() {
  const [episodes, setEpisodes] = useState([]);
  useEffect(() => {
    fetch(FETCH_URL + "recentepisodes", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setEpisodes(data.episodes);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <section
      style={{
        width: "100%",
        background: `url(${bg_green_2})`,
        backgroundSize: "cover",
        paddingTop: "5%",
        paddingBottom: "150px",
      }}
    >
      <Column align="center" justify="center" wrap="nowrap">
        <h2 style={{ color: "#fefefe" }}>LATEST EPISODES</h2>
        <Row width="100%" justify="space-around">
          {episodes.map((episode, index) => {
            return (
              <EpisodeCard
                key={index}
                title={episode.title}
                description={episode.description}
                audioURL={episode.episode_audio}
                audioID={episode.id}
              />
            );
          })}
        </Row>
      </Column>
    </section>
  );
}
