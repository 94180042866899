import styled from "styled-components";

const SphereDiv = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  background: ${(props) => props.background};
  background-image: ${(props) => props.backgroundImage};
  border-radius: ${(props) => props.borderRadius};
  box-shadow: ${(props) => props.shadow};
  z-index: ${(props) => props.zIndex};
  ${(props) => props.customStyle};
`;

export default function Sphere({
  width = "100%",
  height = "100%",
  position = "absolute",
  top = "",
  left = "",
  right = "",
  bottom = "",
  background = "white",
  backgroundImage = "",
  borderRadius = "50%",
  shadow = "",
  zIndex = "2",
  customStyle = "",
}) {
  return (
    <SphereDiv
      width={width}
      height={height}
      position={position}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      background={background}
      backgroundImage={backgroundImage}
      borderRadius={borderRadius}
      shadow={shadow}
      zIndex={zIndex}
      customStyle={customStyle}
    />
  );
}
