import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FETCH_URL } from "utils/globalVariables";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "redux/slices/userSlice";

import LoginForm from "./LoginForm";
import { Row } from "layout/flexbot";
import Sphere from "components/sphere/Sphere";

export default function LoginContainer() {
  const device = useSelector((state) => state.window.device);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //user check to see if user is logged in and redirect to dashboard if they are
  // useEffect(() => {
  //   fetch(FETCH_URL + "login/refresh", {
  //     method: "GET",
  //     credentials: "include",
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         return res.json();
  //       }
  //     })
  //     .then((data) => {
  //       if (data.login) {
  //         console.log("redirecting");
  //         dispatch(LOGIN());
  //         navigate("/dashboard");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // }, []);

  return (
    <Row
      align="center"
      justify="center"
      height="100vh"
      width="100%"
      customStyles="background-color: #373737"
    >
      <Sphere
        width="300px"
        height="300px"
        top="-100px"
        left="20%"
        background="radial-gradient(ellipse at right top, rgba(251,176,64,1) 0%, rgba(141,0,255,1) 100%)"
      />
      <Sphere
        width="150px"
        height="150px"
        top={device === "desktop" ? "20%" : ""}
        bottom={device === "desktop" ? "" : "0"}
        right="20%"
        background="radial-gradient(ellipse at right top, rgba(100,100,100,1) 0%, rgba(10,10,10,1) 70%)"
      />
      <LoginForm />
    </Row>
  );
}
