import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_BLOGS } from "redux/slices/postSlice";
import { FETCH_URL } from "utils/globalVariables";

import FilteredDisplay from "./FilteredDisplay";

import { Column, Row } from "layout/flexbot";
import { ButtonMain } from "components/buttons";

export default function BlogDisplay() {
  const dispatch = useDispatch();
  const blogs = useSelector((state) => state.blog.blogs);

  useEffect(() => {
    fetch(FETCH_URL + "blogs", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        let tempSorted = {};
        data.blogs.map((post) => {
          if (post.category in tempSorted) {
            tempSorted[post.category].push(post);
          } else {
            tempSorted[post.category] = [post];
          }
        });
        dispatch(UPDATE_BLOGS(tempSorted));
      });
  }, []);

  function filterScroll(category) {
    const categoryElement = document.getElementById(category);
    categoryElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  return (
    <Column
      width="min(1200px, 100%)"
      gap="15px"
      customStyles="margin: 0 auto; padding: 75px 0"
    >
      <Row gap="20px" align="center">
        <p style={{ fontSize: "2rem" }}>Categories:</p>
        {Object.keys(blogs).map((category, index) => {
          return (
            <ButtonMain key={index} onClick={() => filterScroll(category)}>
              {category}
            </ButtonMain>
          );
        })}
      </Row>
      {Object.keys(blogs).map((category, index) => {
        return (
          <React.Fragment key={index}>
            <FilteredDisplay category={category} />
          </React.Fragment>
        );
      })}
    </Column>
  );
}
