import { FETCH_URL } from "utils/globalVariables";
import { useEffect, useState } from "react";

import { Column, Row } from "layout/flexbot";
import AudioMain from "components/audio/AudioMain";
import { LogoLink } from "components/links";

//image imports
import instagram_logo from "assets/images/socialMedia/icon-instagram-green.svg";
import twitter_logo from "assets/images/socialMedia/icon-twitter-green.svg";

export default function Introduction({
  name,
  index,
  title,
  about,
  socials = { instagram: "", twitter: "" },
  image,
  episode_number
}) {
  const [episode, setEpisode] = useState({});
  //need fetch request to get the episode data for each of the hosts favorite episode
  useEffect(() => {
    if (episode_number !== undefined && episode_number !== null) {
      fetch(FETCH_URL + "episode/" + episode_number, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setEpisode(data.episode);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    
  }, [episode_number]);

  return (
    <Row index={index} width="100%" gap="40px">
      <img
        src={image}
        alt={`${name} portrait`}
        width='95%'
        style={{ borderRadius: "15px", border: "solid #007030 3px", maxWidth: '400px', margin: '0 auto' }}
      />
      <Column customStyles="flex: 1; margin: 5%">
        <h3 style={{ fontSize: "2rem" }}>{name}</h3>
        <Row align="center" gap="20px">
          <p>{title}</p>
          <Row gap="10px">
            <LogoLink
              image={instagram_logo}
              href={socials.instagram}
              alt={`Logo for instagram. Links to instagram page for ${name}`}
              height="30px"
            />
            <LogoLink
              image={twitter_logo}
              href={socials.twitter}
              alt={`Logo for twitter. Links to twitter page for ${name}`}
              height="30px"
            />
          </Row>
        </Row>
        <hr style={{ width: "min(200px, 90%)", borderTop: "solid #373737 4px" }} />
        <p>{about}</p>
        <Row
          width="auto"
          customStyles="background-color: #373737; border-radius: 15px; padding: 10px; color: #fefefe"
          gap="10px"
        >
          <img
            src={FETCH_URL + "image/episode_default.jpg"}
            alt="Struggling with the bums podcast cover"
            width="100px"
            style={{ borderRadius: "15px" }}
          />
          <div style={{ flex: 1 }}>
            <p>{episode.title}</p>
            <p>{episode.description}</p>
            <AudioMain audioURL={episode.episode_audio} audioID={episode.episode_number} episode={episode}/>
          </div>
        </Row>
      </Column>
    </Row>
  );
}
