const sortByEpisode = (list) =>
  list.sort((a, b) => a.episode_number - b.episode_number);

const sortByParameter = (list, parameter) => {
  let tempList = [...list];
  if (tempList[0][parameter] === Number) {
    tempList.sort((a, b) => a[parameter] - b[parameter]);
  } else {
    tempList.sort((a, b) => {
      if (a[parameter] < b[parameter]) {
        return -1;
      }
      if (a[parameter] > b[parameter]) {
        return 1;
      }
      return 0;
    });
  }
  return tempList;
};

export { sortByEpisode, sortByParameter };
