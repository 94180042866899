//component imports
import { Column, Row } from "layout/flexbot";
import { useSelector } from "react-redux";

//image imports
import eddie_textbubble from "assets/images/profilePictures/eddie_bitmoji_textbubble.png";
import bryce_textbubble from "assets/images/profilePictures/bryce_bitmoji_textbubble.png";
import daniel_textbubble from "assets/images/profilePictures/daniel_bitmoji_textbubble.png";
import microphone from "assets/images/podcast/microphone.png";

export default function Testimonials() {
  const device = useSelector((state) => state.window.device);
  return (
    <section
      style={{
        width: "min(100%, 1300px)",
        paddingBottom: "40px",
        margin: "0 auto",
      }}
    >
      <h2 style={{ textAlign: "center", marginBottom: "40px" }}>The Podcast</h2>
      <Row align="center" justify="center">
        {device !== "mobile" && (
          <img
            src={microphone}
            alt=""
            width="41px"
            style={{ transform: "translateX(-5px)" }}
          />
        )}
      </Row>
      <Row>
        <Column
          gap="30px"
          customStyles={`flex: 1; min-width: min(400px, 100%); ${
            device !== "mobile" &&
            "border-right: solid #000 8px; padding: 0 40px"
          }`}
        >
          <div style={{ position: "relative", height: "350px" }}>
            <img
              src={eddie_textbubble}
              alt=""
              width="100%"
              height="auto"
              style={{
                filter: "drop-shadow(5px 5px 10px #000)",
                WebkitFilter: "drop-shadow(0 0 20px #000)",
              }}
            />
            <p
              style={{
                position: "absolute",
                top: "5%",
                left: "30%",
                right: "5%",
                bottom: "30%",
                overflow: "hidden",
              }}
            >
              The Bums are a group just striving to make a good life. We have so many threads and passions in common that tie us together. And of course, we're all Ducks.
            </p>
          </div>
          <div
            style={{
              position: "relative",
              height: "350px",
              marginLeft: "auto",
            }}
          >
            <img
              src={bryce_textbubble}
              alt=""
              width="100%"
              height="auto"
              style={{
                filter: "drop-shadow(5px 5px 10px #000)",
                WebkitFilter: "drop-shadow(0 0 20px #000)",
              }}
            />
            <p
              style={{
                position: "absolute",
                top: "5%",
                left: "40%",
                right: "5%",
                bottom: "30%",
                overflow: "hidden",
              }}
            >
              The Bums strive to be honest with out audience and ourselves at all times. In doing this we hope to give an authentic take on how we see and navigate life.
            </p>
          </div>
        </Column>
        <Column
          align="center"
          customStyles={`flex: 1; min-width: min(400px, 100%); ${
            device !== "mobile" && "padding: 0 40px"
          }`}
        >
          <div style={{ position: "relative", height: "350px" }}>
            <img
              src={daniel_textbubble}
              alt=""
              width="100%"
              height="auto"
              style={{
                filter: "drop-shadow(5px 5px 10px #000)",
                WebkitFilter: "drop-shadow(0 0 20px #000)",
              }}
            />
            <p
              style={{
                position: "absolute",
                top: "5%",
                left: "5%",
                right: "35%",
                bottom: "30%",
                overflow: "hidden",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </p>
          </div>
        </Column>
      </Row>
    </section>
  );
}
