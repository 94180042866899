import { useSelector } from "react-redux";

//component imports
import { Row } from "layout/flexbot";
import TextInput from "components/input/TextInput";
import { ButtonSubmit } from "components/buttons";

export default function Newsletter() {
  const device = useSelector((state) => state.window.device);
  return (
    <section id='newsletter'
      style={{
        width: "min(1300px, 100%)",
        height: "200px",
        margin: "0 auto",
        backgroundColor: "#373737",
        borderRadius: device !== "mobile" && "0.8rem",
        transform: device !== "mobile" && "translateY(-50%)",
        color: "#fefefe",
      }}
    >
      <Row height="100%" align="center" justify="space-around">
        <p>Sign up for the bums newsletter to stay up to date on new content</p>
        <Row>
          <TextInput placeholder="Email Address" />
          <ButtonSubmit disabled={true} value="Sign Up" />
        </Row>
      </Row>
    </section>
  );
}
