import { Column } from "layout/flexbot";

import Introduction from "./Introduction";

import {
  EDDIE_SOCIALS,
  BRYCE_SOCIALS,
  DANIEL_SOCIALS,
} from "utils/globalVariables";

//image imports
import eddie from "assets/images/profilePictures/eddie_profile.jpg";
import bryce from "assets/images/profilePictures/bryce_profile.jpg";
import daniel from "assets/images/profilePictures/daniel_profile.jpg";

export default function Introductions() {
  const about = [
    {
      name: "Eddie Cano",
      image: eddie,
      socials: EDDIE_SOCIALS,
      statement:
        "Hello bums, this is my profile. As you can all see, my name is Edward “Eddie” Cano. Nice to meet y’all. I met the bums back in college early freshman year, first Bryce then later Daniel. We have been friends since kicking it everyday playing some football. Eventually we just moved to football every Friday night (we often called ourselves the Friday Night Ballers). College was a hell of a journey, moving out from California and being away from family. With the help of the bums, I was able to get through well enough. In fact, it was just about the time of my life. Currently I am bumming my way to a master’s degree in Positive Coaching and Athlete Leadership at the University of Missouri-Columbia (Mizzou). I finished undergrad with a B.S. and double majored in Economics and Psychology. As the story goes, after a night spent opening up to each other we realized how much more we had in common. Then we wanted to share with others and see just how many felt the same and to let them know they weren’t alone. Life can be hard, but it certainly can feel good to know what you are experiencing is normal and there are others going through it too. Never be afraid to open up to your friends, they will be there when life gets tough. Feel free to hit us up here on our site or via social media. Catch y’all real soon. Also, SCO DUCKS.",
      episode_number: 87,
    },
    {
      name: "Bryce",
      image: bryce,
      socials: BRYCE_SOCIALS,
      statement:
        "Yo what’s up Bums my name is Bryce all I do is Winn. I met Eddie like the first day of college and I met Daniel like the first week of my second year in college. We all bonded relatively quickly with each other and our core group of friends which we called the Bums. The Bums originated from our Friday football friend group in which we played football every Friday night from our freshmen year of college to our senior year in college. Currently I am somehow getting my master at the university of Oklahoma. It was around the time I started my masters that me and the bums FaceTimed each other to talk about our struggles, and it was at that moment we realized that we all had similar struggles. From those experiences we decided to start our podcast to talk about the struggles of feeling like Bums. We are three regular guys who have all lived different lives and have experienced a lot, this makes our podcast relatable to almost everyone. Please don’t be shy and interact with our social media, we would love to have discussions with as many people as we can.",
      episode_number: 85,
      },
    {
      name: "Daniel",
      image: daniel,
      socials: DANIEL_SOCIALS,
      statement:
        "What’s up everybody, I’m Daniel Dennis. Yes, I do indeed have two first names that are perfectly balanced that can get pretty confusing super easily. For my background, I met Eddie first and then Bryce later through some pick-up games of football at the University of Oregon. Over time we all became pretty close which led us to where we are currently at. If I had to currently describe myself I would say that I’m just an average guy trying to figure out my life. I’m currently pursuing a second degree at Portland State. My goal is to pursue a life in the medical field and somehow figure out a plan to live in Japan again. Part of what led us as a group to create this Podcast was to just talk about struggles people around our age experience. We all go through things in life and sometimes it feels that we are all alone in our struggles. We created this podcast to just share what we have gone through in hopes that other people could relate and know that they aren’t alone in the struggles they face. If y’all have gone through anything similar or just have an experience to share don’t hesitate to hit us up.",
      episode_number: 7,
      },
  ];
  return (
    <Column
      width="min(1300px, 100%)"
      customStyles="margin: 0 auto; padding: 40px 0"
      gap="40px"
    >
      {about.map((item, index) => {
        return (
          <Introduction
            key={index}
            name={item.name}
            title="host"
            about={item.statement}
            image={item.image}
            socials={item.socials}
            episode_number={item.episode_number}
          />
        );
      })}
    </Column>
  );
}
