import { useEffect, useState } from "react";
import { FETCH_URL } from "utils/globalVariables";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";
import { Column } from "layout/flexbot";

import { ContentForm } from "./ContentForm";
import { DeleteButton, ButtonSubmit } from "components/buttons";

export default function EpisodeForm() {
  const [episode, setEpisode] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.split("/")[3] !== "new") {
      fetch(FETCH_URL + "devepisode/" + location.pathname.split("/")[3], {
        method: "GET",
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          setEpisode(data.episode);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, []);

  function UpdateEpisode(event) {
    if (event.submitter.matches("#submit")) {
      event.preventDefault();
      const form = document.getElementById("episode-form");
      let formdata = new FormData(form);

      if (location.pathname.split("/")[3] === "new") {
        fetch(FETCH_URL + "devepisode/" + 1, {
          method: "POST",
          credentials: "include",
          body: formdata,
        })
          .then((response) => response.json())
          .then((data) => {
            event.target.elements.title.value = "";
            event.target.elements.episode_number.value = "";
            event.target.elements.description.value = "";
            event.target.elements.guests.value = "";
            event.target.elements.act.value = "";
            event.target.elements.spotify_link.value = "";
            event.target.elements.itunes_link.value = "";
            event.target.elements.anchor_link.value = "";
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        fetch(FETCH_URL + "api/devepisode/" + location.pathname.split("/")[3], {
          method: "PUT",
          credentials: "include",
          body: formdata,
        })
          .then((response) => response.json())
          .then((data) => {
            event.target.elements.title.value = "";
            event.target.elements.episode_number.value = "";
            event.target.elements.description.value = "";
            event.target.elements.guests.value = "";
            event.target.elements.act.value = "";
            event.target.elements.spotify_link.value = "";
            event.target.elements.itunes_link.value = "";
            event.target.elements.anchor_link.value = "";
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  }

  useEffect(() => {
    document
      .getElementById("episode-form")
      .addEventListener("submit", UpdateEpisode);
  }, []);

  function DeleteEpisode() {
    fetch(FETCH_URL + "devepisode/" + location.pathname.split("/")[3], {
      method: "DELETE",
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          navigate("/dashboard/episodes");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <section
      id="dev-episode"
      style={{
        width: "min(1300px, 100%)",
        margin: "0 auto",
        color: "#fefefe",
        textAlign: "center",
      }}
    >
      <h1>EDIT EPISODE: {episode.title}</h1>
      <ContentForm id="episode-form">
        <Column align="center">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            name="title"
            id="title"
            placeholder="title"
            defaultValue={episode.title}
          />
        </Column>
        <Column align="center">
          <label htmlFor="episode_number">Episode Number</label>
          <input
            type="number"
            name="episode_number"
            id="episode_number"
            placeholder="episode number"
            defaultValue={episode.episode_number}
          />
        </Column>
        <Column align="center">
          <label htmlFor="description">Description</label>
          <input
            type="text"
            name="description"
            id="description"
            placeholder="description"
            defaultValue={episode.description}
          />
        </Column>
        <Column align="center">
          <label htmlFor="guests">Guests</label>
          <input
            type="text"
            name="guests"
            id="guests"
            placeholder="guests"
            defaultValue={episode.guests}
          />
        </Column>
        <Column align="center">
          <label htmlFor="act">Act</label>
          <input
            type="text"
            name="act"
            id="act"
            placeholder="act"
            defaultValue={episode.act}
          />
        </Column>
        <Column align="center">
          <label htmlFor="spotify_link">Spotify Link</label>
          <input
            type="text"
            name="spotify_link"
            id="spotify_link"
            placeholder="spotify link"
            defaultValue={episode.spotify_link}
          />
        </Column>
        <Column align="center">
          <label htmlFor="itunes_link">Itunes Link</label>
          <input
            type="text"
            name="itunes_link"
            id="itunes_link"
            placeholder="itunes link"
            defaultValue={episode.itunes_link}
          />
        </Column>
        <Column align="center">
          <label htmlFor="anchor_link">Anchor Link</label>
          <input
            type="text"
            name="anchor_link"
            id="anchor_link"
            placeholder="anchor-link"
            defaultValue={episode.anchor_link}
          />
        </Column>
        <Column align="center">
          <label htmlFor="image">Image</label>
          <input type="file" id="image" name="image" />
        </Column>
        <Column align="center">
          <label htmlFor="audio">Audio</label>
          <input type="file" id="audio" name="audio" />
        </Column>
        <ButtonSubmit
          style={{ width: "100%" }}
          type="submit"
          name=""
          id="submit"
        />
        <DeleteButton onClick={() => DeleteEpisode()}>
          Delete Episode
        </DeleteButton>
      </ContentForm>
    </section>
  );
}
