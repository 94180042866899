import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    login: false,
    user: {},
  },
  reducers: {
    //updates user values from a dictionary action
    LOGIN: (state) => {
      state.login = true;
    },
    LOGOUT: (state) => {
      state.login = false;
    },
    UPDATE_USER: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { LOGIN, LOGOUT, UPDATE_USER } = userSlice.actions;
export default userSlice.reducer;
