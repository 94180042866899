import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { FETCH_URL } from "utils/globalVariables";
import { Row, Column } from "layout/flexbot";
import AudioController from "components/audio/AudioController";

export default function SingleBlog() {
  const location = useLocation();
  const [blog, setBlog] = useState({});
  const [episode, setEpisode] = useState({});
  const device = useSelector((state) => state.window.device);

  //analytic recording
  useEffect(() => {
    fetch(FETCH_URL + "/analyticRecord", {
      method: "POST",
      body: JSON.stringify({
        type: "blog",
        id: location.pathname.split("/")[2],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    fetch(FETCH_URL + location.pathname, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setBlog(data.blog);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    if (blog.related_episode !== undefined && blog.related_episode !== null) {
      fetch(FETCH_URL + "/episode/" + blog.related_episode, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setEpisode(data.episode);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [blog]);

  return (
    <section>
      <Column
        gap="20px"
        customStyles={`margin: ${device !== "mobile" ? "75px" : "75px 40px"}`}
      >
        <h1 style={{ fontSize: "3rem", margin: "0 auto" }}>{blog.title}</h1>
        <Row gap="20px">
          <p>Eddie</p>
          <p>{blog.category}</p>
          <p>{blog.date_posted}</p>
        </Row>
        <pre style={{ whiteSpace: "pre-wrap", fontSize: "1.5rem" }}>
          {blog.content}
        </pre>
        <div
          style={{
            width: "min(1300px, 100%)",
            margin: "0 auto",
            padding: "5%",
            backgroundColor: "#373737",
            borderRadius: device !== "mobile" && "0.8rem",
            color: "#fefefe",
            boxShadow: device !== "mobile" && "0 25px 50px #000",
          }}
        >
          <Row
            height="100%"
            width="90%"
            align="center"
            gap="40px"
            customStyles="padding-left: 5%"
          >
            <Column
              width={device !== "mobile" ? "40%" : "100%"}
              customStyles={
                device === "mobile" && "text-align: center; align-items: center"
              }
              height="70%"
              gap="10px"
              wrap="no-wrap"
            >
              <h2 style={{ color: "#007030", fontSize: ".8rem" }}>
                RELATED EPISODE
              </h2>
              <h3 style={{ fontSize: "1.7rem" }}>
                Episode {episode.id} - {episode.title}
              </h3>
              <p style={{ fontSize: ".8rem" }}>{episode.description}</p>
            </Column>
            <AudioController
              audioURL={episode.episode_audio}
              audioID={episode.id}
            />
          </Row>
        </div>
      </Column>
    </section>
  );
}
