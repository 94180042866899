import styled from "styled-components";

const Grid = styled.div.attrs((props) => ({
  id: props.id,
}))`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => props.columnMin}, 1fr)
  );
  grid-template-rows: repeat(
    auto-fill,
    minmax(${(props) => props.rowMin}, 1fr)
  );
  grid-template-areas: ${(props) => props.areas};
  grid-gap: ${(props) => props.gap};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  max-width: ${(props) => props.maxWidth};
  max-height: ${(props) => props.maxHeight};
  background-color: ${(props) => props.backgroundColor};
  ${(props) => props.customStyles};
`;

export default Grid;
