import { useState, useEffect } from "react";
import { FETCH_URL } from "utils/globalVariables";

//component imports
import { PostCard } from "components/cards";
import { Row } from "layout/flexbot";

export default function BlogPreview() {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetch(FETCH_URL + "recentposts", {
      method: "GET",
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error("Error fetching posts");
        }
      })
      .then((data) => {
        setPosts(data.posts);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <section style={{ textAlign: "center", paddingBottom: "100px" }}>
      <h2>DIGGING DEEPER WITH THE BUMS</h2>
      <Row justify="space-around">
        {posts.map((post, index) => {
          return (
            <PostCard
              key={index}
              title={post.title}
              description={post.short_description}
              date={post.date_posted}
            />
          );
        })}
      </Row>
    </section>
  );
}
