import { useState, useEffect } from "react";
import { FETCH_URL } from "utils/globalVariables";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";

import { ContentForm } from "./ContentForm";
import { Column } from "layout/flexbot";
import { ButtonSubmit, DeleteButton } from "components/buttons";

export default function PostForm() {
  const [post, setPost] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(FETCH_URL + "devpost/" + location.pathname.split("/")[3], {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setPost(data.post);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  function UpdateBlog(event) {
    if (event.submitter.matches("#submit")) {
      event.preventDefault();
      const form = document.getElementById("post-form");
      let formdata = new FormData(form);

      if (location.pathname.split("/")[3] === "new") {
        fetch(FETCH_URL + "devpost/" + 1000, {
          method: "POST",
          credentials: "include",
          body: formdata,
        })
          .then((response) => response.json())
          .then((data) => {
            event.target.elements.title.value = "";
            event.target.elements.related_episode.value = "";
            event.target.elements.short_description.value = "";
            event.target.elements.content.value = "";
            event.target.elements.category.value = "";
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        fetch(FETCH_URL + "devpost/" + location.pathname.split("/")[3], {
          method: "PUT",
          credentials: "include",
          body: formdata,
        })
          .then((response) => response.json())
          .then((data) => {
            event.target.elements.title.value = "";
            event.target.elements.related_episode.value = "";
            event.target.elements.short_description.value = "";
            event.target.elements.content.value = "";
            event.target.elements.category.value = "";
            event.target.elements.user_id.value = "";
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  }

  useEffect(() => {
    document.getElementById("post-form").addEventListener("submit", UpdateBlog);
  }, []);

  function DeleteBlog() {
    fetch(FETCH_URL + "devpost/" + location.pathname.split("/")[3], {
      method: "DELETE",
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          navigate("/dashboard/blog");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <section
      id="dev-post"
      style={{
        width: "min(1300px, 100%)",
        margin: "0 auto",
        color: "#fefefe",
        textAlign: "center",
      }}
    >
      <h1>EDIT EPISODE: {post.title}</h1>
      <ContentForm id="post-form">
        <Column align="center">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            name="title"
            id=""
            placeholder="title"
            defaultValue={post.title}
          />
        </Column>
        <Column align="center">
          <label htmlFor="related_episode">Related Episode</label>
          <input
            type="number"
            name="related_episode"
            placeholder="realted episode"
            defaultValue={post.related_episode}
          />
        </Column>
        <Column align="center">
          <label htmlFor="short_description">Short Description</label>
          <input
            type="text"
            name="short_description"
            id=""
            placeholder="shortdescription"
            defaultValue={post.short_description}
          />
        </Column>
        <Column align="center">
          <label htmlFor="content">Content</label>
          <textarea
            type="text"
            name="content"
            id=""
            placeholder="content"
            defaultValue={post.content}
          />
        </Column>
        <Column align="center">
          <label htmlFor="category">Category</label>
          <input
            type="text"
            name="category"
            id=""
            placeholder="category"
            defaultValue={post.category}
          />
        </Column>
        <Column align="center">
          <label htmlFor="user_id">User ID</label>
          <input
            type="text"
            name="user_id"
            placeholder="user id"
            defaultValue={post.user_id}
          />
        </Column>
        <Column align="center">
          <label htmlFor="image">Image</label>
          <input type="file" name="image" />
        </Column>
        <ButtonSubmit type="submit" name="" id="submit" />
        <DeleteButton onClick={() => DeleteBlog()}>DELETE</DeleteButton>
      </ContentForm>
    </section>
  );
}
