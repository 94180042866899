import { Row } from "layout/flexbot";

export default function DashboardHeader() {
  return (
    <header style={{ height: "50px" }}>
      <Row height="100%" align="center" justify="space-between">
        <p>weather</p>
        <Row gap="20px">
          <p>Date</p>
          <p>LD switch</p>
          <p>user</p>
        </Row>
      </Row>
    </header>
  );
}
