import { useState, useEffect } from "react";
import { FETCH_URL } from "utils/globalVariables";
import { useSelector } from "react-redux";

//component imports
import { Row } from "layout/flexbot";
import { PlayButton } from "components/buttons";

//image imports
import soundwave from "assets/images/podcast/soundwave.png";
import play_button from "assets/images/podcast/play_button.png";
import pause_button from "assets/images/podcast/pause_button.png";

const Mask = ({ children, device }) => (
  <div
    style={{
      maskImage: `url(${soundwave})`,
      WebkitMaskImage: `url(${soundwave})`,
      flex: device !== "mobile" && 1,
      width: device === "mobile" && "100%",
      maskSize: "100%",
      WebkitMaskSize: "100%",
      maskRepeat: "no-repeat",
      WebkitMaskRepeat: "no-repeat",
      maskPosition: "center",
      WebkitMaskPosition: "center",
    }}
  >
    {children}
  </div>
);

export default function AudioController({
  audioURL = "https://www2.cs.uic.edu/~i101/SoundFiles/CantinaBand60.wav",
  audioID,
}) {
  const [play, setPlay] = useState(false);
  const [analyticsRecorded, setAnalyticsRecorded] = useState(false);
  const device = useSelector((state) => state.window.device);

  function togglePlay() {
    setPlay(!play);
  }

  function AnalyticRecord() {
    if (Number.isInteger(audioID)) {
      fetch(FETCH_URL + "analyticRecord", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ type: "episode", id: audioID }),
      })
        .then((res) => {
          if (res.status === 200) {
            setAnalyticsRecorded(true);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  useEffect(() => {
    //record analytics on first play
    if (!analyticsRecorded) {
      if (play) {
        AnalyticRecord();
      }
    }
  }, [play]);

  useEffect(() => {
    const audio = document.getElementById(`audio-${audioID}`);
    const playButton = document.getElementById(`play-button-${audioID}`);
    playButton.addEventListener("click", togglePlay);
    if (play) {
      audio.play();
    } else {
      audio.pause();
    }
    return () => {
      playButton.removeEventListener("click", togglePlay);
    };
  }, [play]);

  useEffect(() => {
    const timeBar = document.getElementById(`time-bar-${audioID}`);
    const audio = document.getElementById(`audio-${audioID}`);

    // apply gradient filter to time bar based on audio location in time
    function updateGradient() {
      const percent = (audio.currentTime / audio.duration) * 100;
      timeBar.style.background = `linear-gradient(to right, #007030 0%, #007030 ${percent}%, #fefefe ${percent}%, #fefefe 100%)`;
    }

    // update time bar gradient every 100ms
    setInterval(updateGradient, 100);
  }, []);

  //update audio time based on time bar click
  function updateAudioTime(e) {
    const audio = document.getElementById(`audio-${audioID}`);
    const timeBar = document.getElementById(`time-bar-${audioID}`);
    const timeBarWidth = timeBar.offsetWidth;
    const timeBarX = timeBar.getBoundingClientRect().x;
    const clickX = e.clientX;
    const clickXOffset = clickX - timeBarX;
    const percent = (clickXOffset / timeBarWidth) * 100;
    const newTime = (audio.duration / 100) * percent;
    audio.currentTime = newTime;
  }

  useEffect(() => {
    const timeBar = document.getElementById(`time-bar-${audioID}`);
    timeBar.addEventListener("click", updateAudioTime);
    return () => {
      timeBar.removeEventListener("click", updateAudioTime);
    };
  }, []);

  return (
    <Row
      height="min(75px, 100%)"
      align="center"
      gap="40px"
      customStyles={
        device !== "mobile" ? "flex: 1" : "width: 100%; justify-content: center"
      }
      wrap="wrap"
    >
      <audio
        id={`audio-${audioID}`}
        src={audioURL !== undefined ? FETCH_URL + "audio/" + audioURL : ""}
        type="audio/wav"
      />
      <PlayButton audioID={audioID} play={play} style={{ margin: "0 auto" }}>
        <Row width="100%" height="100%" align="center" justify="center">
          <img
            src={play ? pause_button : play_button}
            alt=""
            height="50%"
            style={!play ? { transform: "translateX(2px)" } : {}}
          />
        </Row>
      </PlayButton>
      <Mask device={device}>
        <div
          id={`time-bar-${audioID}`}
          style={{
            background: "#fefefe",
            flex: 1,
            height: "75px",
            minWidth: "300px",
          }}
        ></div>
      </Mask>
    </Row>
  );
}
