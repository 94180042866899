import { useSelector } from "react-redux";

import { Row, Column } from "layout/flexbot";

import { LogoLink } from "components/links";
import { Link } from "react-router-dom";

import {
  ANCHOR_LINK,
  SPOTIFY_LINK,
  ITUNES_LINK,
  INSTAGRAM_LINK,
} from "utils/globalVariables";

//image imports
import anchor from "assets/images/socialMedia/anchor-logo-white.png";
import spotify from "assets/images/socialMedia/spotify-logo-white.gif";
import apple from "assets/images/socialMedia/itunes-logo-white.png";
import instagram from "assets/images/socialMedia/instagram-logo-white.png";
import twitter from "assets/images/socialMedia/twitter-logo-white.png";

export default function Footer() {
  const login = useSelector((state) => state.user.login);

  return (
    <Column
      width="100%"
      align="center"
      customStyles="padding: 40px 0; background-color: #373737; color: #fefefe"
    >
      <Row gap="20px">
        <LogoLink
          href={ANCHOR_LINK}
          image={anchor}
          alt="Logo for anchor podcasts. Links to achor podcast page for Struggling With The Bums Podcast"
          width="40px"
        />
        <LogoLink
          href={SPOTIFY_LINK}
          image={spotify}
          alt="Logo for spotify. Links to spotify page for Struggling With The Bums Podcast"
          width="40px"
        />
        <LogoLink
          href={ITUNES_LINK}
          image={apple}
          alt="Logo for itunes. Links to itunes page for Struggling With The Bums Podcast"
          width="40px"
        />
        <LogoLink
          href={INSTAGRAM_LINK}
          image={instagram}
          alt="Logo for instagram. Links to instagram page for Struggling With The Bums Podcast"
          width="40px"
        />
        <LogoLink
          image={twitter}
          alt="Logo for twitter. Links to twitter page for Struggling With The Bums Podcast"
          width="40px"
        />
      </Row>
      <Row gap="20px">
        <p>Info</p>
        <p>Support</p>
        <Link style={{ color: "#fefefe" }} to={login ? "dashboard" : "login"}>
          Dev
        </Link>
      </Row>
      <Row>
        <p>Terms of Service</p>
        <p>Privacy Policy</p>
      </Row>
      <p>
        @ 2023, website developed and maintained by{" "}
        <a
          style={{ color: "#fefefe" }}
          href="https://www.linkedin.com/in/daniel-mendoza-88336625b/"
          target="_blank"
          rel="noopener noreferrer"
        >
          DJMWebSolutions
        </a>
      </p>
    </Column>
  );
}
