import styled from "styled-components";
import { useEffect, useState } from "react";
import { FETCH_URL } from "utils/globalVariables";
import { Row, Column } from "layout/flexbot";
import TextInput from "components/input/TextInput";
import { ButtonSubmit } from "components/buttons";

const StyledForm = styled.form`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px 0;
  background-color: #fefefe;
  border-radius: 0.8rem;
  color: #000000;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5;
`;

export default function ContactForm() {
  const [valid, setValid] = useState(false);

  //form submit
  useEffect(() => {
    const form = document.getElementById("contact-form");
    form.addEventListener("submit", (e) => {
      e.preventDefault();
      const data = new FormData(form);
      const object = {};
      data.forEach((value, key) => {
        object[key] = value;
      });
      const json = JSON.stringify(object);
      fetch(FETCH_URL + "email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: json,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    });
  }, []);

  //form pre checks
  //useEffect that creats an event that triggers evetime form inputs change
  useEffect(() => {
    const fName = document.getElementById("firstname");
    const lName = document.getElementById("lastname");
    const email = document.getElementById("email");
    const subject = document.getElementById("subject");
    const message = document.getElementById("content");

    function checkValid() {
      let emailCheck = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm;
      if (
        fName.value.length > 0 &&
        lName.value.length > 0 &&
        email.value.length > 0 &&
        subject.value.length > 0 &&
        message.value.length > 0 &&
        email.value.match(emailCheck)
      ) {
        setValid(true);
      } else {
        setValid(false);
      }
    }

    fName.addEventListener("input", checkValid);
    lName.addEventListener("input", checkValid);
    email.addEventListener("input", checkValid);
    subject.addEventListener("input", checkValid);
    message.addEventListener("input", checkValid);

    return () => {
      fName.removeEventListener("input", checkValid);
      lName.removeEventListener("input", checkValid);
      email.removeEventListener("input", checkValid);
      subject.removeEventListener("input", checkValid);
      message.removeEventListener("input", checkValid);
    };
  }, []);

  return (
    <StyledForm id="contact-form">
      <Row justify="space-around" width="100%">
        <Column customStyles="flex: 1" align="center">
          <label htmlFor="firstname">Firstname</label>
          <TextInput
            type="text"
            name="firstname"
            id="firstname"
            placeholder="firstname"
            style={{ minWidth: "min(400px, 90%)" }}
          />
        </Column>
        <Column customStyles="flex: 1" align="center">
          <label htmlFor="lastname">Lastname</label>
          <TextInput
            type="text"
            name="lastname"
            id="lastname"
            placeholder="lastname"
            style={{ minWidth: "min(400px, 90%)" }}
          />
        </Column>
      </Row>
      <Row justify="space-around" width="100%">
        <Column customStyles="flex: 1" align="center">
          <label htmlFor="subject">Email Subject</label>
          <TextInput
            type="text"
            name="subject"
            id="subject"
            placeholder="subject"
            style={{ minWidth: "min(400px, 90%)" }}
          />
        </Column>
        <Column customStyles="flex: 1" align="center">
          <label htmlFor="email">Your Email</label>
          <TextInput
            type="text"
            name="email"
            id="email"
            placeholder="email"
            style={{ minWidth: "min(400px, 90%)" }}
          />
        </Column>
      </Row>
      <label htmlFor="content">Content</label>
      <textarea
        name="content"
        id="content"
        rows="1"
        cols="50"
        wrap="pysical"
        style={{ minHeight: "300px", width: "90%", border: "solid black 4px" }}
      />
      <ButtonSubmit disabled={!valid && true} />
    </StyledForm>
  );
}
