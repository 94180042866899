import { useEffect } from "react";
import { FETCH_URL } from "utils/globalVariables";
//component imports
import Jumbotron from "./jumbotron/Jumbotron";
import PodcastListen from "./podcastListen/PodcastListen";

export default function Podcast() {
  useEffect(() => {
    fetch(FETCH_URL + "analyticRecord", {
      method: "POST",
      body: JSON.stringify({ type: "episodes" }),
    })
      .then((res) => res.json())
      .then((data) => {
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <div>
      <Jumbotron />
      <PodcastListen />
    </div>
  );
}
