import { createSlice } from "@reduxjs/toolkit";

const formatAnalytics = (analytics) => {
  let formattedAnalytics = {};
  Object.keys(analytics).forEach((key) => {
    formattedAnalytics[key] = analytics[key];
  });
  return formattedAnalytics;
};

const formatDateAnalytics = (analytics) => {
  let formattedAnalytics = [];
  Object.keys(analytics).forEach((key) => {
    let test = key.split("/");
    let date = new Date(test[2], test[1] - 1, test[0]);
    let temp = date.toISOString().slice(0, 10);
    formattedAnalytics.push({ day: temp, value: analytics[key] });
  });
  return formattedAnalytics;
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState: {
    analyticss: {},
    daily_visits: [{ 1: 2 }],
    site_visits: 0,
    blog_page_visits: 0,
    episode_page_visits: 0,
    single_episode_listens: [{ 1: 2 }],
    single_blog_visits: [{ 1: 2 }],
  },
  reducers: {
    //updates user values from a dictionary action
    UPDATE_ANALYTICS: (state, action) => {
      state.current_analytics = action.payload;
      state.daily_visits = formatDateAnalytics(action.payload.daily_visits);
      state.site_visits = action.payload.site_visits;
      state.blog_page_visits = action.payload.blog_page_visits;
      state.episode_page_visits = action.payload.episode_page_visits;
      state.single_episode_listens = formatAnalytics(
        action.payload.single_episode_listens
      );
      state.single_blog_visits = formatAnalytics(
        action.payload.single_blog_visits
      );
    },
  },
});

export const { UPDATE_ANALYTICS } = analyticsSlice.actions;
export default analyticsSlice.reducer;
