import PodcastMenu from "../podcastMenu/PodcastMenu";

export default function PodcastListen() {
  return (
    <section style={{ width: "min(1200px, 100%)", margin: "0 auto" }}>
      <h2>Listen To The Podcast</h2>
      <PodcastMenu />
      <div style={{ height: "100px" }}></div>
    </section>
  );
}
