import styled from "styled-components";
import { Link } from "react-router-dom";

const NavLink = styled(Link)`
  color: #fefefe;
  font-size: 1.5rem;
  text-decoration: none;
  &:hover {
    color: #fffce6;
  }
`;

export default NavLink;
