import { configureStore } from "@reduxjs/toolkit";
import windowSlice from "./slices/windowSlice";
import episodeSlice from "./slices/episodeSlice";
import postSlice from "./slices/postSlice";
import userSlice from "./slices/userSlice";
import analyticsSlice from "./slices/analyticsSlice";

export default configureStore({
  reducer: {
    window: windowSlice,
    episode: episodeSlice,
    blog: postSlice,
    user: userSlice,
    analytics: analyticsSlice,
  },
});
