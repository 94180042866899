import styled from "styled-components";

const ButtonSubmit = styled.input.attrs((props) => ({
  disabled: props.disabled,
  type: "submit",
  value: props.value || "Submit",
}))`
  color: ${(props) => props.color || "#fefefe"};
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  background-color: ${(props) => props.backgroundColor || "#020202"};
  border-radius: ${(props) => props.borderRadius || "0px"};
  border: ${(props) => props.border || "2px solid #020202"};
  font-size: ${(props) => props.fontSize || "1.2rem"};
  padding: ${(props) => props.padding || ".5rem"};
  ${(props) => props.customStyle};
  &:disabled {
    filter: brightness(0.5);
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${(props) => props.hoverBackgroundColor || "#fefefe"};
    color: ${(props) => props.hoverColor || "#020202"};
    border: ${(props) => props.hoverBorder || "2px solid #fefefe"};
  }
`;
export default ButtonSubmit;
