import { useEffect, useState } from "react";

import FixedNav from "./FixedNav";
import { Column } from "layout/flexbot";
import { NavLink } from "components/links";

export default function MobileNav() {
  const [navStyles, setNavStyles] = useState("transparent");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setNavStyles("background: #373737; box-shadow: 5px 0 10px #000");
      } else {
        setNavStyles("background: transparent; box-shadow: none");
      }
    });
  }, []);

  const toggleMenu = () => {
    const navDropdown = document.getElementById("nav-dropdown");
    if (navDropdown.style.height === "0px") {
      navDropdown.style.height = "80vh";
    } else {
      navDropdown.style.height = "0px";
    }
  };

  return (
    <FixedNav align="center" justify="space-between" customStyles={navStyles}>
      <button
        onClick={() => toggleMenu()}
        style={{
          backgroundColor: "#fefefe",
          flex: 1,
          height: "100%",
          width: "100%",
          textAlign: "center",
          color: "#000",
        }}
      >
        <h1>The Bums</h1>
      </button>
      <Column
        id="nav-dropdown"
        align="center"
        justify="space-around"
        width="100%"
        height="0px"
        wrap="no-wrap"
        customStyles="overflow: hidden; transition: all .5s; background-color: #373737"
      >
        <NavLink to="/" onClick={() => toggleMenu()}>
          Home
        </NavLink>
        <NavLink to="podcast" onClick={() => toggleMenu()}>
          Podcast
        </NavLink>
        <NavLink to="blog" onClick={() => toggleMenu()}>
          Blog
        </NavLink>
        <NavLink to="about" onClick={() => toggleMenu()}>
          About
        </NavLink>
        <NavLink to="contact" onClick={() => toggleMenu()}>
          Contact
        </NavLink>
      </Column>
    </FixedNav>
  );
}
