import { useEffect, useState } from "react";
import { FETCH_URL } from "utils/globalVariables";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LOGIN } from "redux/slices/userSlice";

import { Column } from "layout/flexbot";
import Input from "components/input/TextInput";
import SubmitButton from "components/buttons/ButtonSubmit";
import Form from "components/forms/form/Form";

export default function LoginForm() {
  const [valid, setValid] = useState(false);
  const [error, setError] = useState({ username: true, password: true });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function Login(event) {
    event.preventDefault();
    const username = event.target.elements.username.value;
    const password = event.target.elements.password.value;

    fetch(FETCH_URL + "login", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ username: username, password: password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if(data.login === true) {
           dispatch(LOGIN());
            navigate("/dashboard");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  useEffect(() => {
    document.getElementById("login-form").addEventListener("submit", Login);
  }, []);

  //useEffect that creats an event that triggers evetime form inputs change
  useEffect(() => {
    const username = document.getElementById("username");
    const password = document.getElementById("password");

    function checkValid() {
      let usernameTest = /^[a-zA-Z0-9_\.]+$/;
      var passwordTest = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (username.value.length > 0 && password.value.length > 0) {
        if (
          usernameTest.test(username.value) &&
          passwordTest.test(password.value)
        ) {
          setValid(true);
        } else {
          setValid(false);
        }
      } else {
        setValid(false);
      }
      if (usernameTest.test(username.value)) {
        setError((prev) => ({ ...prev, username: false }));
      } else {
        setError((prev) => ({ ...prev, username: true }));
      }
      if (passwordTest.test(password.value)) {
        setError((prev) => ({ ...prev, password: false }));
      } else {
        setError((prev) => ({ ...prev, password: true }));
      }
    }

    username.addEventListener("input", checkValid);
    password.addEventListener("input", checkValid);

    return () => {
      username.removeEventListener("input", checkValid);
      password.removeEventListener("input", checkValid);
    };
  }, []);

  return (
    <Form id="login-form" onSubmit={() => Login()}>
      <Column align="center" justify="center" gap="10px">
        <h1 style={{ color: "white" }}>Sign In</h1>
        <Input
          id="username"
          name="username"
          placeholder="username"
          width="100%"
          height="40px"
          background="none"
          borderColor="white"
          borderRadius="5px"
        ></Input>
        <p>{error.username ? "please enter a valid username" : ""}</p>
        <Input
          id="password"
          name="password"
          password={true}
          placeholder="password"
          width="100%"
          height="40px"
          background="none"
          borderColor="white"
          borderRadius="5px"
        ></Input>
        <p>{error.password ? "please enter a valid password" : ""}</p>
        <SubmitButton
          disabled={!valid}
          width="100%"
          height="40px"
          borderRadius="5px"
          customStyle="background: rgba(251,176,64,1); background: linear-gradient(20deg, rgba(141,0,255,1) -40%, rgba(251,176,64,1) 100%)"
        />
      </Column>
    </Form>
  );
}
