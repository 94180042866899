import { createSlice } from "@reduxjs/toolkit";

export const episodeSlice = createSlice({
  name: "episode",
  initialState: {
    episodes: {},
    numerical_episodes: [],
    current_episode: 1,
    displayed_episodes: "all",
  },
  reducers: {
    //updates user values from a dictionary action
    CHANGE_EPISODE: (state, action) => {
      state.current_episode = action.payload;
    },
    UPDATE_EPISODES: (state, action) => {
      state.episodes = action.payload;
    },
    UPDATE_NUMERICAL_EPISODES: (state, action) => {

      state.numerical_episodes = action.payload.sort((a, b) => a.episode_number - b.episode_number);
    },
    CHANGE_DISPLAYED_EPISODES: (state, action) => {
      state.displayed_episodes = action.payload;
      state.episode_display = true;
    },
    UPDATE_DISPLAY: (state) => {
      state.episode_display = !state.episode_display;
    },
  },
});

export const {
  CHANGE_EPISODE,
  UPDATE_EPISODES,
  CHANGE_DISPLAYED_EPISODES,
  UPDATE_DISPLAY,
  UPDATE_NUMERICAL_EPISODES,
} = episodeSlice.actions;
export default episodeSlice.reducer;
