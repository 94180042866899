//redux slice
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  width: 0,
  height: 0,
  device: "desktop",
  orientation: "landscape",
};

const windowSlice = createSlice({
  name: "window",
  initialState,
  reducers: {
    setWindow(state, action) {
      state.width = action.payload.width;
      state.height = action.payload.height;
      if (action.payload.width < 768) {
        state.device = "mobile";
      } else if (action.payload.width < 992) {
        state.device = "tablet";
      } else {
        state.device = "desktop";
      }
      if (action.payload.width < action.payload.height) {
        state.orientation = "portrait";
      } else {
        state.orientation = "landscape";
      }
    },
  },
});

export const { setWindow } = windowSlice.actions;

export default windowSlice.reducer;
