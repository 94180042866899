//component imports
import Jumbotron from "./jumbotron/Jumbotron";
import FeaturedEpisode from "./featuredEpisode/FeaturedEpisode";
import Testimonials from "./testimonials/Testimonials";
import LatestEpisodes from "./lastestEpisodes/LatestEpisodes";
import Newsletter from "./newsletter/Newsletter";
import BlogPreview from "./blogPreview/BlogPreview";

export default function Home() {
  return (
    <div className="home">
      <Jumbotron />
      <FeaturedEpisode />
      <Testimonials />
      <LatestEpisodes />
      <Newsletter />
      <BlogPreview />
    </div>
  );
}
