import { useSelector } from "react-redux";

//component imports
import { Row, Column } from "layout/flexbot";
import { LinkMain } from "components/links";
import { ITUNES_LINK, SPOTIFY_LINK, ANCHOR_LINK } from "utils/globalVariables";

import bg_green_2 from "assets/images/backgrounds/bg-green-2.png";

export default function Jumbotron() {
  const device = useSelector((state) => state.window.device);

  const statement =
    "         Hello fellow bums. We know what you’re thinking, “not another podcast” “what is a bum?” “why should we listen?”. We are here to answer those questions and more. You see, we are three good friends who met in college, and we thought we knew a lot about each other. Yet, one night on a call, we realized we had all been going through some tough stuff without ever sharing it with each other. We’re supposed to be friends yet never opened up to one another? We decided then to start being more open. This led to how we could get more people to open up to their good friends and how we could start talking about important stuff in our lives. Our solution was a podcast where we could discuss topics, spark conversations, and hopefully connect people. So far, we have well over 30 episodes covering topics like growing up, religion, mental health, medicine, and even having interviewed two soldiers who have served overseas. We want people to understand the struggles you face and thoughts you have are normal, you are not alone.";
  return (
    <section
      style={{
        background: `url(${bg_green_2})`,
        padding: "75px 0",
        backgroundSize: "cover",
        height: device !== "mobile" && "min(600px, 100vh)",
        width: "100%",
        color: "#fefefe",
      }}
    >
      <Column
        height="100%"
        align="center"
        justify="center"
        width="min(1200px, 100%)"
        customStyles="margin: 0 auto"
        wrap='no-wrap'
      >
        <p style={{ fontSize: device !== "mobile" ? "3rem" : "2rem" }}>
          Explore amazing personal stories, life lessons, and hilarious ... from
          three average bums!
        </p>
        <Row wrap="wrap" gap="40px">
          {device === "mobile" && (
            <p
              style={{
                width: "90%",
                fontSize: "1rem",
                margin: "0 auto",
                textAlign: "center",
              }}
            >
              {statement}
            </p>
          )}
          {device !== "mobile" && (
            <pre
              style={{
                width: "50%",
                minWidth: "400px",
                fontSize: "1.1rem",
                whiteSpace: "pre-wrap",
              }}
            >
              {statement}
            </pre>
          )}
          <Column
            width={device === "mobile" ? "100%" : "auto"}
            align={device === "mobile" && "center"}
          >
            <p>Subscribe</p>
            <Row gap="40px">
              <LinkMain href={ITUNES_LINK}>Apple</LinkMain>
              <LinkMain href={SPOTIFY_LINK}>Spotify</LinkMain>
              <LinkMain href={ANCHOR_LINK}>Anchor</LinkMain>
            </Row>
          </Column>
        </Row>
      </Column>
    </section>
  );
}
