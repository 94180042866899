import styled from "styled-components";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.gap || "0"};
  flex-wrap: ${(props) => props.wrap || "wrap"};
  justify-content: ${(props) => props.justify || "start"};
  align-items: ${(props) => props.align || "start"};
  height: ${(props) => props.height || "auto"};
  width: ${(props) => props.width || "auto"};
  ${(props) => props.customStyles}
`;

export default Row;
