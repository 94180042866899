import styled from "styled-components";

const FormContainer = styled.form.attrs((props) => ({
  id: props.id,
}))`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.borderRadius};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  max-width: ${(props) => props.maxWidth};
  max-height: ${(props) => props.maxHeight};
`;

export default function Form({
  children,
  id,
  width = "auto",
  height = "auto",
  padding = "0px",
  margin = "0px",
}) {
  return (
    <FormContainer
      id={id}
      width={width}
      height={height}
      padding={padding}
      margin={margin}
    >
      {children}
    </FormContainer>
  );
}
