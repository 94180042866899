import styled from "styled-components";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: ${(props) => props.wrap || "wrap"};
  gap: ${(props) => props.gap || "0"};
  justify-content: ${(props) => props.justify || "start"};
  align-items: ${(props) => props.align || "start"};
  height: ${(props) => props.height || "auto"};
  width: ${(props) => props.width || "auto"};
  ${(props) => props.customStyles}
`;

export default Column;
