import { useState, useEffect } from "react";
import { FETCH_URL } from "utils/globalVariables";

//component imports
import { Card } from "..";
import { Link } from "react-router-dom";
import { Row } from "layout/flexbot";

//image imports
import eddie_bitmoji from "assets/images/profilePictures/eddie_bitmoji.png";
import bryce_bitmoji from "assets/images/profilePictures/bryce_bitmoji.png";
import daniel_bitmoji from "assets/images/profilePictures/daniel_bitmoji.png";

export default function EpisodeCard({
  title,
  description,
  date = "1/1/04",
  link = "/default_post",
  author = "eddie",
  image = "blog_default.jpeg",
}) {
  const [authorImage, setAuthorImage] = useState(eddie_bitmoji);
  const [formattedDate, setFormattedDate] = useState(date);

  useEffect(() => {
    const tempDate = new Date(date);
    setFormattedDate(tempDate.toISOString().slice(0, 10));
  }, [date]);

  useEffect(() => {
    switch (author) {
      case "eddie":
        setAuthorImage(eddie_bitmoji);
        break;
      case "bryce":
        setAuthorImage(bryce_bitmoji);
        break;
      case "daniel":
        setAuthorImage(daniel_bitmoji);
        break;
      default:
        setAuthorImage(eddie_bitmoji);
        break;
    }
  }, [author]);

  return (
    <Card
      width="min(250px, 100%)"
      customStyles="position: relative"
      backgroundColor="#fefefe"
    >
      <img src={FETCH_URL + "image/" + image} alt="" width="100%" />
      <Row align="center" gap="20px">
        <div
          style={{
            background: "#007030",
            height: "40px",
            width: "40px",
            borderRadius: "50%",
            overflow: "hidden",
          }}
        >
          <img src={authorImage} alt="" width="90%" />
        </div>
        <p>{formattedDate}</p>
      </Row>
      <h3>{title}</h3>
      <p>{description}</p>
      <Link to={`${link}`}>Read Now</Link>
    </Card>
  );
}
