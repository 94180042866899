import styled from "styled-components";

const StyledText = styled.span`
    opacity: 0;
    animation: fade-in ${(props) => props.fade}s ease-in-out forwards;
    transform:translateY(30px);
    display: inline-block;
    @keyframes fade-in {
        to {
            opacity: 1;
            transform:translateY(0);
        }
`;

export default function FadeText({ text, fade = 1 }) {
  return <StyledText fade={fade}>{text}</StyledText>;
}
