import styled from "styled-components";

const TextInput = styled.input`
  border: none;
  border-bottom: ${(props) => props.borderBottom || "4px solid #020202"};
  background-color: ${(props) => props.backgroundColor || "transparent"};
  color: ${(props) => props.color || "#4D5859"};
  font-size: ${(props) => props.fontSize || "1.2rem"};
  font-weight: ${(props) => props.fontWeight || "400"};
  padding: ${(props) => props.padding || ".5rem"};
  transition: all 0.2s;
  &:focus {
    outline: none;
    border-bottom: ${(props) => props.focusBorderBottom || "1px solid #007030"};
  }
`;

export default TextInput;
