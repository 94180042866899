import styled from "styled-components";

const Card = styled.div`
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  background-color: ${(props) => props.backgroundColor || "#323232"};
  color: ${(props) => props.color || "#4D5859"};
  font-size: ${(props) => props.fontSize || "1.2rem"};
  font-weight: ${(props) => props.fontWeight || "400"};
  padding: ${(props) => props.padding || ".5rem 1.5rem"};
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.borderRadius || "0.4rem"};
  box-shadow: ${(props) => props.shadow || "5px 5px 5px #000"};
  ${(props) => props.customStyles || ""}
`;

export default Card;
