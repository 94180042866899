import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setWindow } from "redux/slices/windowSlice";
import { Routes, Route, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { FETCH_URL } from "utils/globalVariables";

//component imports
import { MainNav } from "layout/navigation";
import Footer from "layout/footer/Footer";
import LoginContainer from "pages/dashboard/login/LoginContainer";
import PostForm from "components/forms/PostForm";
import EpisodeForm from "components/forms/EpisodeForm";
import {
  Dashboard,
  DashboardHome,
  Analytics,
  ContentManager,
} from "pages/dashboard";

//page imports
import Home from "pages/home/Home";
import Podcast from "pages/podcast/Podcast";
import Blog from "pages/blog/Blog";
import About from "pages/about/About";
import SingleBlog from "pages/singleBlog/SingleBlog";
import Contact from "pages/contact/Contact";

export default function Container() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [siteVisited, setSiteVisited] = useState(false);

  //analytic recording for total page views
  useEffect(() => {
    if (siteVisited) return;
    fetch(FETCH_URL + "analyticRecord", {
      method: "POST",
      body: JSON.stringify({ type: "home" }),
    })
      .then((res) => {
        if (res.status === 200) {
          setSiteVisited(true);
          return res.json();
        }
      })
      .then((data) => {})
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  //window resize logic for responsive design
  function handleResize() {
    let dimensions = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    dispatch(setWindow(dimensions));
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!location.pathname.includes("/dashboard") ? <MainNav /> : null}
      <Routes>
        <Route index element={<Home />} />
        <Route path="podcast" element={<Podcast />} />
        <Route path="blog" element={<Outlet />}>
          <Route index element={<Blog />} />
          <Route path=":blogId" element={<SingleBlog />} />
        </Route>
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="login" element={<LoginContainer />} />
        <Route path="dashboard" element={<Dashboard />}>
          <Route index element={<DashboardHome />} />
          <Route path="analytics" element={<Analytics />} />
          <Route path="podcast" element={<Outlet />}>
            <Route index element={<ContentManager type="episodes" />} />
            <Route path=":episodeId" element={<EpisodeForm />} />
          </Route>
          <Route path="blog" element={<Outlet />}>
            <Route index element={<ContentManager type="blogs" />} />
            <Route path=":blogId" element={<PostForm />} />
          </Route>
        </Route>
      </Routes>
      {!location.pathname.includes("/dashboard") ? <Footer /> : null}
    </>
  );
}
