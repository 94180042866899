import { useState, useEffect } from "react";
import { FETCH_URL } from "utils/globalVariables";

//component imports
import { Row } from "layout/flexbot";
import { PlayButton } from "components/buttons";

//image imports
import play_button from "assets/images/podcast/play_button.png";
import pause_button from "assets/images/podcast/pause_button.png";

export default function AudioMain({
  audioURL = "https://www2.cs.uic.edu/~i101/SoundFiles/CantinaBand60.wav",
  audioID,
  episode = {},
}) {
  const [play, setPlay] = useState(false);
  const [analyticsRecorded, setAnalyticsRecorded] = useState(false);

  function togglePlay() {
    setPlay(!play);
  }

  useEffect(() => {
    //record analytics on first play
    if (!analyticsRecorded) {
      if (Number.isInteger(audioID)) {
        fetch(FETCH_URL + "analyticRecord", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ type: "episode", id: audioID }),
        })
          .then((res) => res.json())
          .then((data) => {
            setAnalyticsRecorded(true);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  }, [play]);

  //reset player when audioID changes
  useEffect(() => {
    setPlay(false);
    setAnalyticsRecorded(false);
  }, [audioID]);

  useEffect(() => {
    const audio = document.getElementById(`audio-${audioID}`);
    const playButton = document.getElementById(`play-button-${audioID}`);
    playButton.addEventListener("click", togglePlay);
    if (play) {
      audio.play();
    } else {
      audio.pause();
    }
    return () => {
      playButton.removeEventListener("click", togglePlay);
    };
  }, [play, audioID]);

  useEffect(() => {
    const timeBar = document.getElementById(`time-bar-${audioID}`);
    const audio = document.getElementById(`audio-${audioID}`);

    // apply gradient filter to time bar based on audio location in time
    function updateGradient() {
      const percent = (audio.currentTime / audio.duration) * 100;
      timeBar.style.background = `linear-gradient(to right, #007030 0%, #007030 ${percent}%, #fefefe ${percent}%, #fefefe 100%)`;
    }

    // update time bar gradient every 100ms
    setInterval(updateGradient, 100);
  }, []);

  //update audio time based on time bar click
  function updateAudioTime(e) {
    const audio = document.getElementById(`audio-${audioID}`);
    const timeBar = document.getElementById(`time-bar-${audioID}`);
    const timeBarWidth = timeBar.offsetWidth;
    const timeBarX = timeBar.getBoundingClientRect().x;
    const clickX = e.clientX;
    const clickXOffset = clickX - timeBarX;
    const percent = (clickXOffset / timeBarWidth) * 100;
    const newTime = (audio.duration / 100) * percent;
    audio.currentTime = newTime;
  }

  useEffect(() => {
    const timeBar = document.getElementById(`time-bar-${audioID}`);
    timeBar.addEventListener("click", updateAudioTime);
    return () => {
      timeBar.removeEventListener("click", updateAudioTime);
    };
  }, []);

  return (
    <Row
      width="95%"
      gap="10px"
      wrap="wrap"
      justify="center"
      customStyles="margin: 0 auto"
    >
      <audio
        id={`audio-${audioID}`}
        src={audioURL !== undefined ? FETCH_URL + "audio/" + audioURL : ""}
        type="audio/wav"
      />
      <PlayButton audioID={audioID} width="40px" height="40px">
        <Row width="100%" height="100%" align="center" justify="center">
            <img
                src={play ? pause_button : play_button}
                alt=""
                height="50%"
                style={!play ? { transform: "translateX(2px)" } : {}}
            />
            </Row>
      </PlayButton>
      <div style={{ flex: 1 }}>
        <Row gap="10px">
          <p>{episode.date_posted}</p>
          <p>{episode.act}</p>
          <p>{episode.guests}</p>
        </Row>
        <div
          id={`time-bar-${audioID}`}
          style={{
            background: "#fefefe",
            flex: 1,
            height: "5px",
            width: "100%",
          }}
        ></div>
      </div>
    </Row>
  );
}
