import AboutHeader from "./header/AboutHeader";
import Introductions from "./introductions/Introductions";

export default function About() {
  return (
    <div>
      <AboutHeader />
      <Introductions />
    </div>
  );
}
