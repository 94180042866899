import styled from "styled-components";

const StyledLink = styled.a.attrs((props) => ({
  target: "_blank",
  rel: "noopener noreferrer",
}))``;

export default function LogoLink({ href, width, height, image, alt }) {
  return (
    <StyledLink href={href}>
      <img width={width} height={height} src={image} alt={alt} />
    </StyledLink>
  );
}
